import articleImg from "assets/article.jpg";
import recipeImg from "assets/recipeImg.jpg"

const MOCKED_DESCRIPTION =
  "Qui eiusmod nulla qui sit minim voluptate ut quis sint aute. Et aute duis qui qui tempor cupidatat est et in laborum. Qui excepteur nisi anim proident non quis nulla et enim enim culpa. Cillum duis qui quis dolore elit veniam magna ad qui anim sit ad.";


const MOCKED_RECIPE_DESCRIPTION="Qui eiusmod nulla qui sit minim voluptate ut quis sint aute. Et aute duis qui qui tempor cupidatat est et in laborum. Qui "


export const mockedArticle = {
  title: "Title",
  description: "description",
  content: "Lorem ipsum",
  image: articleImg,
  date: new Date(),
  author: "Moi",
  readTime: 5,
};

export const mockedRecipe= {
  title:" Nom de la recette",
  image: recipeImg,
  ingredients: "ingredients",
  step:"Step",
  date: new Date(),
  duration:"Durée",
  side_note:"Remarques"
};


export const mockedArticleList = [
  {
    id: 1,
    title: "Titre de l'article",
    description: MOCKED_DESCRIPTION,
    img: articleImg,
  },
  {
    id: 2,
    title: "Titre de l'article 2",
    description: MOCKED_DESCRIPTION,
    img: articleImg,
  },
  {
    id: 3,
    title: "Titre de l'article 3",
    description: MOCKED_DESCRIPTION,
    img: articleImg,
  },
  {
    id: 4,
    title: "Titre de l'article 4",
    description: MOCKED_DESCRIPTION,
    img: articleImg,
  },

 
];
export const mockedRecipesList=[
  {
    id:4,
    title:"Groseille et Myrtylle",
    content:MOCKED_RECIPE_DESCRIPTION,
    img:recipeImg,

    

  }, 
  {
    id:5,
    title:"Baume pour les lèvre",
    content:MOCKED_RECIPE_DESCRIPTION,
    img:recipeImg,
  },
  {
    id:6,
    title:"Grog au citron",
    content: MOCKED_RECIPE_DESCRIPTION,
    img:recipeImg,
  }
]