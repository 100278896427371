import React, { useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";

export const ProductCard = () => {
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [price, setPrice] = useState()

  const handleAddToCart = () => {
    setIsAddedToCart(true);
    // 
  };

  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite);
    // 
  };

  return (
    <div className="product-card">
      {isAddedToCart && (
        <div className="added-to-cart-overlay">
          {/* Added to cart overlay content */}
          <p>L'article a bien été ajouté au panier</p>
        </div>
      )}

      <div className="labels">
        {/* Labels */}
        <div className="label">Made in France</div>
      </div>

      {/* Favorite */}
      <div className="favorite">
        <button onClick={handleToggleFavorite}>
          {isFavorite ? (
            <AiOutlineHeart
              className="favorite-icon"
              fill="currentColor"
              viewBox="0 0 24 24"
            />
          ) : (
            <AiOutlineHeart
              className="favorite-icon"
              fill="currentColor"
              viewBox="0 0 24 24"
            />
          )}
        </button>
      </div>

      {/* Product details */}
      <div className="product-details">
        <img src="product-image-url" alt="Product" />
        <p className="brand">Terra</p>
        <p className="product-name">Bougie aromathérapie</p>
        <p className="variant"></p>
      </div>

      {/* Pricing */}
      <div className="pricing">
        <span className="price">14,95 €</span>
        <button onClick={handleAddToCart}>Ajouter au panier</button>
      </div>
    </div>
  );
};
