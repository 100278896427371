

import styles from "./SingleRecipePage.module.css";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

export const SingleRecipePage = () => {
  const [recipe, setRecipes] = useState([]);
  const { id: recipeId } = useParams();

  useEffect(() => {
    const getRecipeById = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/recipe/${recipeId}`
        );
        setRecipes(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    getRecipeById();
  }, [recipeId]);

  const ingredientsArray =
    recipe.ingredients && typeof recipe.ingredients === "string"
      ? recipe.ingredients.split("\n").map((ingredient) => ingredient.trim())
      : [];
  
  const stepArr = 
    recipe.step && typeof recipe.step === "string"
    ? recipe.step.split("\n").map((step) => step.trim())
    : []

 const noteArr =
 recipe.side_note && typeof recipe.side_note === "string"
    ? recipe.side_note.split("\n").map((note) => note.trim())
    : []

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{recipe.recipe_name}</h1>
      <img
        className={styles.image}
        src={recipe.recipe_img}
        alt="img d'article"
      />
      <div className={styles.informationContainer}>
        <strong>{recipe.author}</strong>
        {/* {recipe.date.toLocaleDateString()} */}
        <p className={styles.informations}>Préparation - {recipe.duration} min</p>
      </div>
      <ul>
        <h3>Ingrédients :</h3>
      {ingredientsArray.map((ingredient, index) => (
          <li key={index}>{ingredient}</li>
        ))}
       
      </ul>
      
      <h3>Préparation :</h3>
      <ul className={styles.step}>
      {stepArr.map((step, index) => (
          <li key={index}>{step}</li>
        ))}
      </ul>
     
      <h3>Note :</h3>
      <p>Voici quelques options alternatives si vous manquez de temps ou d'envie &#128522; :</p>
      {noteArr.map((note, index) => ( 
        <li ><Link to={note} className={styles.listLink} key={index} target="_blank">{note}</Link></li>    
      ))}

      // seulement si il y a une reference a un article de blog
    </div> 
  );
};
