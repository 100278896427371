import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Card.module.css";
import DOMPurify from "dompurify";


const Card = ({ article }) => {
  const [showFullContent, setShowFullContent] = useState(false);

 

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const contentToShow = showFullContent
    ? article.post_content
    : article.post_content.substring(0, 150); // Adjust the character limit as needed

  const navigate = useNavigate();
  const cleanHTML= DOMPurify.sanitize(contentToShow)

  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/blog/article/${article.post_id}`)}
    >
      {/* <div className={styles.header}>
       <div className={styles.tags}>
          santé
       </div>
       <div className={styles.tags}>
        vegan
       </div>
       <div className={styles.icon}>

       </div>

      </div> */}
      <div className={styles.headCardDate}>
        <p className={styles.dateArticle}>
          {new Date(article.post_date).toLocaleDateString("fr-FR")}
        </p>
       
      </div>

      <div>
        <img
          src={article.post_img}
          className={styles.image}
          alt={article.post_title}
        />
      </div>
      
      {/* <div className={styles.titleLine} /> */}
      <div className={styles.informationsContainer}>
        <h2 className={styles.title}>{article.post_title}</h2>
        <p className={styles.description}  dangerouslySetInnerHTML={{ __html: cleanHTML }}></p>
        {article.post_content.length > 140 && (
          <Link className={styles.readMore} onClick={toggleContent}>
            {showFullContent ? "Read less" : "Read more"}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Card;
