import { Card } from "components";
import { mockedRecipesList } from "mocks/data.mock";
import styles from "./RecipesPage.module.css";
import { RecipeCard } from "components/RecipeCard/RecipeCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { SearchRecipe } from "components/SearchRecipe/SearchRecipe";


export const RecipesPage = () => {
  const [allRecipes, setAllRecipes] = useState([]);
  const [research, setResearch] = useState();

  useEffect(() => {
    const getAllRecipes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/recipes`
        );
        setAllRecipes(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    getAllRecipes();
  }, []);

  let researchHandler = (e) => {
    e.preventDefault();
    setResearch(e.target.value);
  };
  const resultsToDisplay = allRecipes.filter((recipe) => {
    return recipe.recipe_name
      .toLowerCase()
      .match(research?.toLowerCase() || "");
  });

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <SearchRecipe researchHandler={researchHandler} />
      </div>
      <div className={styles.cards}>
        {resultsToDisplay.map((recipe) => (
          <RecipeCard key={recipe.id} recipe={recipe} />
        ))}
      </div>
    </div>
  );
};
