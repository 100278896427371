import { Link } from "react-router-dom";
import logoname from "assets/logoname_cutted.png";
import styles from "./Navigation.module.css";
import { MobileNavigation } from "components/MobileNavigation/MobileNavigation";
import { useState, useEffect } from "react";

const NAV_LINKS = [
  {
    to: "/blog",
    text: "Blog",
  },
  {
    to:"/recettes", 
    text:"Recettes"

  },
  // {
  //   to: "/shop",
  //   text: "Boutique",
  // },
  // {
  //   to: "/subscription",
  //   text: "Abonnement",
  // },
  // {
  //   to: "/contact",
  //   text: "À propos de nous",
  // },

];

const Navigation = () => {
  const [isResponsive, setIsResponsive] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsResponsive(true);
      } else {
        setIsResponsive(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isResponsive ? (
        <MobileNavigation />
      ) : (
        <nav className={styles.container}>
          <Link to="/">
            <img src={logoname} className={styles.logo} alt="Terra" />
          </Link>
          <div className={styles.linksContainer}>
            {NAV_LINKS.map((link) => (
              <Link key={link.to} to={link.to} className={styles.link}>
                {link.text}
              </Link>
            ))}
          </div>
        </nav>
      )}
    </div>
  );
};

export default Navigation;
