import { Navigation } from "./components";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { lazy, Suspense } from "react";
import { RecipesPage } from "pages/RecipesPage/RecipesPage";
import { SingleRecipePage } from "pages/SingleRecipePage/SingleRecipePage";
import { ProductCard } from "components/ProductCard/ProductCard";

const HomePage = lazy(() => import("pages/HomePage/HomePage"));
const ContactPage = lazy(() => import("pages/ContactPage/ContactPage"));
const SubscriptionPage = lazy(() =>
  import("pages/SubscriptionPage/SubscriptionPage")
);
const BlogPage = lazy(() => import("pages/BlogPage/BlogPage"));
const BlogArticlePage = lazy(() =>
  import("pages/BlogArticlePage/BlogArticlePage")
);


function App() {
  return (
    <div className='App'>
      <Suspense fallback={<>Loading...</>}>
        <Navigation />

        <Routes>
          <Route exact path='/' element={<HomePage />} />
          <Route exact path='/contact' element={<ContactPage />} />
          <Route exact path='/subscription' element={<SubscriptionPage />} />
          <Route exact path='/blog' element={<BlogPage />} />
          <Route exact path='/blog/article/:id' element={<BlogArticlePage />} />
          <Route exact path= '/recettes' element={<RecipesPage/>}/>
          <Route exact path='/recettes/recette/:id'element={<SingleRecipePage/>}/>
          {/* <Route exact path='/shop' element={<ShopPage/>}/> */}
          <Route exact path='shop/product/:id' element={<ProductCard/>}/>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
