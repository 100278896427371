import styles from "./Searchrecipe.module.css"
export const SearchRecipe = ({researchHandler}) => {
  return (
    <div className={styles.containerSearch}>
      <input
        placeholder="rechercher une recette"
        type="text"
        onChange={researchHandler}
        className={styles.inputSearch}
      />
    </div>
  )
}
