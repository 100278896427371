import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GrMenu } from "react-icons/gr";
import logoname from "../../assets/logoname_cutted.png";
import styles from "./MobileNavigation.module.css";

export const MobileNavigation = () => {
  const NAV_LINKS = [
    {
      to: "/blog",
      text: "Blog",
    },
    {
      to:"/recettes", 
      text:"Recettes"

    },
    // {
    //   to: "/shop",
    //   text: "Boutique",
    // },
    // {
    //   to: "/subscription",
    //   text: "Abonnement",
    // },
    {
      to: "/contact",
      text: "À propos de nous",
    },
  ];

  const [isShown, setIsShown] = useState(false);

  const handleOnclick = () => {
    setIsShown(!isShown);
  };

  return (
    <nav className={styles.mobileContainer}>
      <Link to="/">
        <img src={logoname} className={styles.logo} alt="Terra" />
      </Link>
      <Link>
        <GrMenu size={28} className={styles.menuIcon} onClick={handleOnclick} />
      </Link>
      {isShown && (
        <div className={styles.linksContainer}>
          {NAV_LINKS.map((link) => (
            <Link key={link.to} to={link.to} className={styles.link}>
              {link.text}
            </Link>
          ))}
        </div>
      )}
    </nav>
  );
};
