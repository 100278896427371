import { useNavigate } from "react-router-dom";
import styles from "./RecipeCard.module.css"

export const RecipeCard = ({recipe}) => {

    const navigate = useNavigate();
  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/recettes/recette/${recipe.recipe_id}`)}
    >
       {/* <div className={styles.header}>
       <div className={styles.tags}>
          santé
       </div>
       <div className={styles.tags}>
        vegan
       </div>
       <div className={styles.icon}>

       </div>
      </div> */}
      <div>
      <img src={recipe.recipe_img} className={styles.image} alt={recipe.title} />
      </div>
   
      {/* <div className={styles.titleLine} /> */}
      <div className={styles.informationsContainer}>
        <h2 className={styles.fontTextTitle}>{recipe.recipe_name}</h2>
        <p className={styles.fontText}>{recipe.content}</p>
      </div>
    </div>
  )
}
